<template>
  <div class="trip-form">
    <form class="form" method="post">
      <div class="entity" :key="index" v-for="(entity, index) in generalSchema">
        <div class="entity-label">
          {{ entity | removeUnderscores | uppercase }}
        </div>
        <input
          v-model="trip[entity]"
          class="entity-input"
          type="text"
          :name="entity"
          :placeholder="entity"
          required
          @click="$event.target.select()"
          @keyup.enter="$event.target.blur()"
        />
      </div>
    </form>
    <table>
      <thead>
        <tr class="trow">
          <th class="thead" :key="index" v-for="(entity, index) in titleSchema">
            {{ entity | removeUnderscores | uppercase }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="trow" :key="index" v-for="(row, index) in trip.commodity">
          <td class="tcol" :key="col" v-for="(entity, col) in commoditySchema">
            <input
              class="entity-value"
              type="text"
              v-model="row[entity]"
              @click="$event.target.select()"
              @keyup.enter="$event.target.blur()"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["trip"],
  computed: {
    generalSchema: function() {
      return ["zip_from", "zip_to", "city_from", "city_to"];
    },
    commoditySchema: function() {
      return ["hu_count", "dimensions", "weight", "description"];
    },
    titleSchema: function() {
      return ["Handling Units", "Dims LxWxH (in)", "Weight (lbs)", "Commodity"];
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.trip-form {
  background-color: #fff;
  text-align: left;
  padding: 20px;
  margin-bottom: 10px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  box-shadow: 1px 2px 10px 0px #888888;

  display: flex;
  flex-wrap: wrap;
}

.form {
  max-width: 100%;
  padding-bottom: 20px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .entity {
    max-width: 100%;
    padding: 10px 0;
    flex: 1 1 100%;

    display: flex;
    flex-wrap: wrap;

    @include for-tablet-portrait-up {
      width: 50%;
      flex-basis: 50%;
      flex-grow: 0;
    }

    .entity-label {
      color: #333;
      flex: 1 1 30%;
      font-weight: bold;
      padding-right: 10px;
      text-align: right;
      text-transform: uppercase;
      font-size: 0.7em;
      letter-spacing: 0.5px;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include for-tablet-portrait-up {
        flex-basis: 30%;
      }
    }

    .entity-input {
      flex: 1 1 70%;
      padding: 6px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      min-width: 0;

      @include for-tablet-portrait-up {
        flex-basis: 70%;
      }
    }
  }
}

table,
th,
td {
  border: 1px solid #ddd;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  color: var(--app-font-color);
  table-layout: fixed;
  overflow: hidden;
  background-color: #fff;

  .thead {
    background-color: #eceff1;
  }

  .thead,
  .tcol {
    padding: 5px 3px;
  }
}

.entity-value {
  width: 100%;
  border: none;
  text-align: center;
  padding: 5px 10px;
}
</style>
