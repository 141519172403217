<template>
  <div class="list-table">
    <header class="table-header">
      <OptionButton
        :disabled="type === 'nonLtl' || emails.length === 0"
        class="delete-button"
        title="delete rows"
        @click.native="deleteRows"
      />
      <div class="search-section">
        <SearchSelectBox style="display: inline-block" :options="schema" />
        <SearchBar @searchEmails="searchEmails" />
      </div>
    </header>
    <table class="table">
      <thead>
        <tr>
          <th class="title">
            <CustomCheckbox
              v-if="type !== 'nonLtl' && emails.length !== 0"
              class="delete-icon checkbox"
              @change.native="checkAllRows($event.target.checked)"
            />
          </th>
          <th :key="index" v-for="(column, index) in schema">
            <button
              class="column-title toggle-button"
              @click="sortTable($event.target)"
            >
              {{ column | removeUnderscores }}
            </button>
          </th>
          <th v-if="['sent', 'webhookFails'].includes(type)">JSON</th>
        </tr>
      </thead>
      <tbody v-if="emails.length === 0">
        <tr :key="index" v-for="(row, index) in emails"></tr>
        <td
          v-if="['sent', 'webhookFails'].includes(type)"
          :colspan="schema.length + 2"
        >
          <strong>No emails to show</strong>
        </td>
        <td v-else :colspan="schema.length + 1">
          <strong>No emails to show</strong>
        </td>
      </tbody>
      <tbody v-else>
        <tr :key="index" v-for="(row, index) in emails">
          <td>
            <CustomCheckbox
              v-if="type !== 'nonLtl'"
              class="delete-icon with-pointer checkbox checkbox-item"
            />
          </td>
          <td
            class="with-pointer"
            :key="index"
            v-for="(columnValue, index) in filter(row)"
            @click="emailSelected(row.custom_id)"
          >
            <label type="text" class="with-pointer">{{ row[index] }}</label>
          </td>
          <td v-if="['sent', 'webhookFails'].includes(type)">
            <font-awesome-icon
              class="with-pointer"
              icon="eye"
              @click="showJSON(row.custom_id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import OptionButton from "@/components/OptionButton";
import CustomCheckbox from "@/components/CustomCheckbox";
import SearchSelectBox from "@/components/SearchSelectBox";
import SearchBar from "@/components/SearchBar";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ListTable",
  props: {
    type: String,
    dir: String
  },
  components: {
    OptionButton,
    CustomCheckbox,
    SearchSelectBox,
    SearchBar
  },
  computed: {
    ...mapState("emailList", ["emails"]),
    schema: function() {
      return ["custom_id", "created_at", "email_subject", "username"];
    }
  },
  data: function() {
    return {
      titleIcon: "▲"
    };
  },
  methods: {
    ...mapActions("emailList", ["sortEmails", "deleteEmails"]),
    ...mapMutations("emailList", ["setEmails"]),
    filter: function({ custom_id, created_at, email_subject, username }) {
      return { custom_id, created_at, email_subject, username };
    },
    showJSON: function(id) {
      window.open(
        `${process.env.VUE_APP_BASE_URL}/api/v1/quote/${id}`,
        "_blank"
      );
    },
    deleteRows: async function() {
      try {
        let emails = [];
        let checkboxes = document.querySelectorAll(".checkbox-item > input");
        for (let i = checkboxes.length - 1; i >= 0; i--) {
          if (checkboxes[i].checked) emails.push(this.emails[i].custom_id);
        }
        await this.deleteEmails(emails);
      } catch (err) {
        console.error(err.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Could not delete emails from database: ${err.message}`
        });
      }
      this.checkAllRows(false);
    },
    checkAllRows: function(state) {
      let checkboxes = document.querySelectorAll(".checkbox-item > input");
      checkboxes.forEach((element) => {
        element.checked = state;
      });
    },
    emailSelected: function(id) {
      this.$router.push({
        name: "details",
        params: { id, type: this.type }
      });
    },
    searchEmails: function(value) {
      // get the searchBox value
      // let searchCriteria = this.$el.querySelector(".selectbox").value;
      // make http request to get the data. Then asign it to settabledata
      // this.tableData = this.emails.filter((row) => {
      //   const rowValue = row[searchCriteria].toLowerCase();
      //   return rowValue.indexOf(value.toLowerCase()) !== -1;
      // });
      this.$emit("searchEmails", value);
    },
    sortedItems: function() {
      this.emails.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return this.emails;
    },
    sortTable: function(button) {
      let dir = "";
      const buttonValue = button.innerHTML;
      if (this.titleIcon === "▼") {
        this.titleIcon = "▲";
        dir = "desc";
      } else {
        this.titleIcon = "▼";
        dir = "asc";
      }
      const column = buttonValue
        .toLowerCase()
        .split(" ")
        .join("_")
        .slice(1, buttonValue.length - 3);
      this.sortEmails({
        columnData: column,
        dir: dir
      });
    }
  },
  created: function() {
    this.titleIcon = this.dir === "asc" ? "▼" : "▲";
    this.sortedItems();
    this.tableData = this.emails;
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.list-table {
  transition: 0.1s;
}

.table-header {
  color: #ffffff;
  border: 1px solid #bcbcbc;
  padding: 8px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  margin-bottom: 5px;
  padding: 16px 10px;
}
::v-deep .delete-button {
  & > button.button {
    background-color: #00557e;
    padding: 14px 18px;
    margin: 10px;
  }
}

button.toggle-button {
  padding: 0;
  font-weight: bold;
  background-color: transparent;
  border-style: none;
  outline: none;
  text-transform: capitalize;
}

.details {
  margin: 5px;
}

.table {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #ffffff;
}

.table-header,
.table {
  box-shadow: 1px 2px 10px 0px #888888;
  border-radius: 3px;
}

th,
td {
  text-align: center;
  padding: 8px;
  font-size: 0.85em;
  word-wrap: break-word;
  padding: 10px 15px;
}

.table th {
  font-size: 0.7em;
  border-top: none;
  border-bottom: none;
}

thead {
  color: #00557e;
  background-color: #ebebeb;
}

.column-title {
  color: #00557e;
  font-size: 0.87rem;
}

td {
  border-bottom: 1px solid #eee;
  background: #f8f8f8;
  color: #666666;
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}

tbody tr:hover {
  background-color: #eee;
}

label {
  background: transparent;
  border: none;
  width: 100%;
  text-align: center;
  color: #666666;
  font-size: 0.75rem;
}

.with-pointer {
  cursor: pointer;
  color: #666666;
}

div.search-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
}

div.searchbar-wrapper {
  width: calc(100% * 2 / 3);
}

/* MEDIA QUERIES: RESPONSIVE DESIGN */
@media (max-width: 767px) {
  .table-header {
    justify-content: center;
    flex-direction: column-reverse;
    padding: 10px;
  }

  div.search-section {
    display: block;
    width: 100% !important;
    margin-bottom: 10px;
  }

  div.selectbox-wrapper {
    display: inline-block;
    width: 100% !important;
    margin-bottom: 10px;
  }

  div.searchbar-wrapper {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  div.searchbar-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 1023px) {
  .table {
    table-layout: fixed;
  }

  div.search-section {
    width: 80%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  div.search-section {
    width: 70%;
  }
}

@media (min-width: 1280px) and (max-width: 1799px) {
  div.search-section {
    width: 50%;
  }
}

@media (min-width: 1800px) and (max-width: 1919px) {
  div.search-section {
    width: 40%;
  }
}

@media (min-width: 1920px) {
  div.search-section {
    width: 40%;
  }
}
</style>
