<template>
  <div class="hard-fail">
    <template v-if="isLoading">
      <LoadingSpinner text="Loading Email..." />
    </template>
    <template v-else>
      <div class="hard-fail-container">
        <header>
          <img
            class="hard-fail-container__header-img"
            src="../assets/GLT-LOGISTICS.svg"
            alt=""
          />
          <b-alert
            class="hard-fail-container__header-msg"
            v-if="!verified"
            show
            variant="info"
            >Please complete the missing information below so we can finalize
            your quote request.</b-alert
          >
          <b-alert
            class="hard-fail-container__header-msg"
            v-else
            show
            variant="success"
            >Thanks!<br />
            Your missing information has been updated and your quote will be
            sent shortly.</b-alert
          >
        </header>
        <div v-if="!verified" class="hard-fail-main">
          <div class="hard-fail-main__form">
            <h4>Missing Information</h4>
            <b-tabs content-class="mt-3" v-if="email.hauls.length !== 0">
              <b-tab
                :title="'Trip ' + (index + 1)"
                :key="index"
                v-for="(trip, index) in email.hauls"
              >
                <form class="trip-form" method="post">
                  <div :key="index" v-for="(entity, index) in generalSchema">
                    <label class="trip-form__label" for="entity">{{
                      entity | removeUnderscores | capitalize
                    }}</label>
                    <b-form-input
                      :id="entity"
                      :name="entity"
                      v-model="trip[entity]"
                      :key="inputRender"
                      :state="trip[entity + 'State']"
                      @keyup.enter="updateInput(entity, trip)"
                      @keydown.tab="updateInput(entity)"
                      :placeholder="
                        (entity + '...') | removeUnderscores | capitalize
                      "
                      size="sm"
                      list="zip_from"
                      class="trip-form__input"
                      autocomplete="off"
                      required
                      @click="$event.target.select()"
                    ></b-form-input>
                  </div>
                </form>
                <b-table-simple bordered class="trip-table" responsive>
                  <b-thead>
                    <b-tr class="trow">
                      <b-th
                        class="thead"
                        :key="index"
                        v-for="(entity, index) in titleSchema"
                      >
                        {{ entity | removeUnderscores | capitalize }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      class="trow"
                      :key="index"
                      v-for="(row, index) in trip.commodity"
                    >
                      <b-td
                        class="tcol"
                        :key="col"
                        v-for="(entity, col) in commoditySchema"
                      >
                        <b-form-input
                          class="trip-table__entity-value"
                          type="text"
                          v-model="row[entity]"
                          @click="$event.target.select()"
                          @keyup.enter="updateInputCommodity(row, entity)"
                          @keydown.tab="updateInputCommodity(row, entity)"
                          :key="inputRender"
                          :state="row[entity + 'State']"
                        ></b-form-input>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-tab>
              <b-overlay
                :show="sendingQuote"
                rounded
                opacity="0.6"
                bg-color="#ebecec"
                spinner-small
                class="d-inline-block"
              >
                <button class="trip-button" @click="confirmTrips($event)">
                  Submit
                </button>
              </b-overlay>
            </b-tabs>
            <div v-else>
              <b-alert show variant="danger">No trips in the quote</b-alert>
            </div>
          </div>
          <div class="hard-fail-main__email">
            <h4>Original Email</h4>
            <Attachments infoPrincipalView="hardFail" />
            <section class="email-html" v-html="email.html_message"></section>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as shared from "@/utils/shared";
import * as api from "@/api/services";
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Attachments from "@/components/Attachments.vue";
export default {
  name: "HardfailGLT",
  components: {
    LoadingSpinner,
    Attachments
  },
  props: {
    id: String
  },
  data: function() {
    return {
      verified: false,
      isLoading: true,
      email: null,
      sendingQuote: false,
      standardized: null,
      msg: "",
      inputRender: 0
    };
  },
  computed: {
    generalSchema: function() {
      return ["zip_from", "zip_to", "city_from", "city_to"];
    },
    commoditySchema: function() {
      return ["hu_count", "dimensions", "weight"];
    },
    titleSchema: function() {
      return ["Handling Units", "Dims LxWxH (in)", "Weight (lbs)"];
    }
  },
  async created() {
    this.isLoading = true;
    await this.infoQuote();
  },
  methods: {
    async infoQuote() {
      let quote = await this.$store.dispatch(
        "incompleteHardfailGlt/getHardfailGlt",
        this.id
      );
      if (quote.status === 200) {
        this.email = quote.data;
        this.isLoading = false;
        this.renderComodity();
      } else {
        this.isLoading = false;
        this.verified = true;
      }
    },
    async confirmTrips() {
      this.sendingQuote = true;
      const [valid, message] = shared.validateMissingEntities(this.email.hauls);
      if (valid) {
        this.checkInfo();
        this.getStandardized();
      } else {
        Swal.fire({
          icon: "warning",
          title: "Oops... Missing required entities",
          text: message
        });
        this.checkInfo();
        this.sendingQuote = false;
      }
    },
    async getStandardized() {
      let send = 0;
      for (let i of this.email.hauls) {
        for (let commodity of i.commodity) {
          let huCount = await api.getStandardizedEntity(
            "hu_count",
            commodity["hu_count"]
          );
          let dimensions = await api.getStandardizedEntity(
            "dimensions",
            commodity["dimensions"]
          );
          let weight = await api.getStandardizedEntity(
            "weight",
            commodity["weight"]
          );
          commodity["hu_count"] = huCount.data[0];
          commodity["dimensions"] = dimensions.data[0];
          commodity["weight"] = weight.data[0];
        }
        let zipTo = await api.getStandardizedEntity("zip_code", i["zip_to"]);
        let zipFrom = await api.getStandardizedEntity(
          "zip_code",
          i["zip_from"]
        );
        if (zipFrom.status === 200 && zipTo.status === 200) {
          if (
            zipFrom.data[0][1] != "Not Acceptable" &&
            zipTo.data[0][1] != "Not Acceptable" &&
            zipFrom.data[0][0] != null &&
            zipFrom.data[0][0] != null
          ) {
            i["zip_from"] = zipFrom.data[0][0];
            i["city_from"] = zipFrom.data[0][1];
            i["zip_to"] = zipTo.data[0][0];
            i["city_to"] = zipTo.data[0][1];
            send += 1;
          } else {
            Swal.fire("Oops...", `Please enter a valid Zipcode`, "warning");
            this.sendingQuote = false;
            if (zipFrom.data[0][1] == "Not Acceptable") {
              i["zip_fromState"] = false;
              this.inputRender += 1;
            }
            if (zipTo.data[0][1] == "Not Acceptable") {
              i["zip_toState"] = false;
              this.inputRender += 1;
            }
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "Oops... please try again"
          });
          this.sendingQuote = false;
        }
      }
      if (send == this.email.hauls.length) {
        this.sendTrips();
      }
    },
    async sendTrips() {
      let info = {};
      info.custom_id = this.id;
      info.haul = this.email.hauls;
      let response = await this.$store.dispatch(
        "incompleteHardfailGlt/updateHardfailGlt",
        info
      );
      if (response.status === 204) {
        this.verified = true;
      }
      this.sendingQuote = false;
      return response;
    },
    renderComodity() {
      this.email.hauls.map((trip, index) => {
        if (this.email.hauls[index].commodity.length === 0) {
          let commodity = {};
          commodity.hu_count = "";
          commodity.dimensions = "";
          commodity.weight = "";
          trip.commodity = [commodity];
        }
        return trip;
      });
    },
    async updateInput(value, trip) {
      if (value == "zip_to" || value == "zip_from") {
        this.standardized = await api.getStandardizedEntity(
          "zip_code",
          trip[value]
        );
        this.msg = "Zip code";
      } else if (value == "city_to" || value == "city_from") {
        this.standardized = await api.getStandardizedEntity(
          "city",
          trip[value]
        );
        this.msg = "City";
      }
      if (this.standardized.status == 200) {
        if (
          this.standardized.data[0][1] !== "Not Acceptable" &&
          this.standardized.data[0][0] !== "NOT ACCEPTABLE" &&
          trip[value] !== null &&
          trip[value] !== ""
        ) {
          trip[value + "State"] = true;
          this.inputRender += 1;
          trip[value] =
            value == "zip_to" || value == "zip_from"
              ? this.standardized.data[0][0]
              : this.standardized.data[0][1];
          if (value == "zip_to") {
            trip["city_to"] = this.standardized.data[0][1];
            trip["city_toState"] = true;
            this.inputRender += 1;
          } else if (value == "zip_from") {
            trip["city_from"] = this.standardized.data[0][1];
            trip["city_fromState"] = true;
            this.inputRender += 1;
          } else if (value == "city_to") {
            trip["zip_to"] = this.standardized.data[0][0];
            trip["zip_toState"] = true;
            this.inputRender += 1;
          } else if (value == "city_from") {
            trip["zip_from"] = this.standardized.data[0][0];
            trip["zip_fromState"] = true;
            this.inputRender += 1;
          }
        } else if (
          this.standardized.data[0][1] == "Not Acceptable" ||
          this.standardized.data[0][0] == "NOT ACCEPTABLE" ||
          trip[value] == null ||
          trip[value] == ""
        ) {
          Swal.fire("Oops...", `Please enter a valid ${this.msg}`, "warning");
          trip[value + "State"] = false;
          this.inputRender += 1;
          if (value == "zip_to") {
            trip["city_to"] = "NOT ACCEPTABLE";
            trip["city_toState"] = false;
            this.inputRender += 1;
          } else if (value == "zip_from") {
            trip["city_from"] = "NOT ACCEPTABLE";
            trip["city_fromState"] = false;
            this.inputRender += 1;
          } else if (value == "city_to") {
            trip["zip_to"] = "NOT ACCEPTABLE";
            trip["zip_toState"] = false;
            this.inputRender += 1;
          } else if (value == "city_from") {
            trip["zip_from"] = "NOT ACCEPTABLE";
            trip["zip_fromState"] = false;
            this.inputRender += 1;
          }
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Oops... Missing required entities",
          text: "please try again"
        });
      }
    },
    async updateInputCommodity(row, entity) {
      let standardizedCommodity = await api.getStandardizedEntity(
        entity,
        row[entity]
      );
      row[entity] = standardizedCommodity.data[0];
      if (standardizedCommodity.status == 200) {
        if (row[entity] !== null && row[entity] !== "") {
          row[entity + "State"] = true;
          this.inputRender += 1;
        } else if (row[entity] == null || row[entity] == "") {
          row[entity + "State"] = false;
          this.inputRender += 1;
        }
      }
    },
    checkInfo() {
      for (let i of this.email.hauls) {
        for (let commodity of i.commodity) {
          this.commoditySchema.forEach((entity) => {
            if (!commodity[entity]) {
              commodity[entity + "State"] = false;
            } else {
              commodity[entity + "State"] = true;
            }
          });
        }
        this.generalSchema.forEach((entity) => {
          if (!i[entity]) {
            i[entity + "State"] = false;
          } else {
            i[entity + "State"] = true;
          }
        });
      }
      this.inputRender += 1;
    }
  }
};
</script>

<style scoped lang="scss">
.hard-fail {
  background-image: url("../assets/containers.jpeg");
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
}
.hard-fail-container {
  margin: 2rem 1.5rem;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 5px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: auto;
  &__header-img {
    width: 10rem;
    margin: 0 0 1rem 0;
  }
  &__header-msg {
    font-size: 18px;
    margin: 10px;
  }
  .hard-fail-container .tabs {
    border: 1px solid white;
    padding: 10px;
    border-radius: 5px;
  }
  .tabs {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.5);
  }
}
::v-deep .nav-link {
  color: #7f7f7f;
}
::v-deep .nav-link.active {
  color: #47a3b9;
  background: none;
  outline: none;
  border: none;
  border-bottom: 2px solid #47a3b9;
}
.hard-fail-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
  &__form {
    margin: 10px;
    width: 45%;
  }
  &__email {
    margin: 10px;
    width: 50%;
  }
}
h4 {
  color: #47a3b9;
}

.trip-form {
  margin: 18px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  &__label {
    width: 100%;
    text-align: start;
    margin: 0.6rem 0;
    font-weight: 500;
  }
  &__input {
    width: 215px;
    font-size: 13px;
    padding: 1rem;
    background-color: transparent;
  }
}
.table thead th {
  vertical-align: middle;
  color: #7f7f7f;
  border: 1px solid #04ccfc;
  border-radius: 5px;
}
.table b-table-simple,
b-th,
b-td {
  border-radius: 5px;
  vertical-align: middle;
}
b-th {
  padding: 5px;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #04ccfc;
}
td {
  background: white;
}
.trip-table {
  margin: 30px 0px;
  &__entity-value {
    background-color: transparent;
    border-color: transparent;
  }
}
.trip-button {
  color: #ffffff;
  font-weight: bold;
  background-color: #47a3b9;
  padding: 8px 16px;
  border: #47a3b9;
  outline: none;
  margin: 10px 10px 10px 0;
  transition: background-color 250ms ease-in-out;
  text-transform: capitalize;
  border-radius: 15px;
  &:hover {
    background-color: var(--button-hover-color);
  }
}
.tabs {
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
}
.email-html {
  background-color: #fff;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: scroll;
  height: 90%;
  padding: 15px;
  width: 100%;
  min-height: 30.8rem;
}
::v-deep .attachments__title {
  font-size: 1rem;
  color: #47a3b9;
  font-weight: none;
}

::v-deep .attachments__button {
  color: #47a3b9;
  background-color: white;
  border: 1px solid #47a3b9;
  &:hover {
    background-color: #47a3b9;
    color: white;
  }
}
@media (max-width: 1080px) {
  .hard-fail-main {
    flex-direction: column-reverse;
    &__form {
      width: 100%;
    }
    &__email {
      width: 100%;
    }
  }
  .trip-form {
    justify-content: space-between;
  }
  .trip-table {
    overflow: auto;
  }
}
</style>
