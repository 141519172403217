<template>
  <div class="hard-fail">
    <template v-if="isLoading">
      <LoadingSpinner text="Loading Email..." />
    </template>
    <template v-else>
      <header class="hardfail-header">
        <div class="logo">
          <LogoPanel
            height="60px"
            :link="true"
            :tmsLogo="tmsConfig.tmsLoginLogo"
          />
        </div>
        <div v-if="!verified" class="message">
          <span>
            Please complete the missing information below so we can finalize
            your quote request.
          </span>
        </div>
        <div v-else class="thanks-message">
          <span>Thanks!</span>
        </div>
      </header>
      <template v-if="!verified">
        <div class="wrapper info-wrapper">
          <h4 class="wrapper-title">Missing Information</h4>
          <div v-if="email.haul.length !== 0" class="form-wrapper">
            <div class="tab-menu">
              <template v-for="(trip, index) in email.haul">
                <button
                  :key="index"
                  :class="{ active: index === 0 }"
                  class="tab-link"
                  @click="setActiveTrip(index)"
                >
                  Trip {{ index + 1 }}
                </button>
              </template>
            </div>
            <template v-for="(trip, index) in email.haul">
              <div class="trip" :class="{ active: index === 0 }" :key="index">
                <TripForm :trip="trip" />
                <button class="submit-button" @click="confirmTrips($event)">
                  Submit
                </button>
              </div>
            </template>
          </div>
          <div v-else>No trips in the quote</div>
        </div>
        <div class="wrapper email-wrapper">
          <h4 class="wrapper-title">Original Email</h4>
          <div class="email-html" v-html="email.html_message"></div>
        </div>
      </template>
      <template v-else>
        <div class="wrapper thanks-wrapper">
          Your missing information has been updated and your quote will be sent
          shortly.
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as api from "@/api/services";
import * as shared from "@/utils/shared";
import tmsConfig from "@/utils/tmsConfig";
import LoadingSpinner from "@/components/LoadingSpinner";
import LogoPanel from "@/components/LogoPanel";
import TripForm from "@/components/TripForm";

export default {
  name: "Hardfail",
  components: {
    LoadingSpinner,
    LogoPanel,
    TripForm
  },
  props: {
    id: String
  },
  data: function() {
    return {
      isLoading: true,
      verified: false,
      date: "",
      email: {},
      missingEntities: Array,
      missingEntitiesMessage: String,
      tmsConfig
    };
  },
  methods: {
    setActiveTrip: function(index) {
      const tabs = this.$el.querySelectorAll(".tab-link");
      const trips = this.$el.querySelectorAll(".trip");

      trips.forEach((trip) => trip.classList.remove("active"));
      trips[index].classList.add("active");
      tabs.forEach((tab) => tab.classList.remove("active"));
      tabs[index].classList.add("active");
    },
    confirmTrips: function() {
      const [valid, message] = shared.validateMissingEntities(this.email.haul);
      if (valid) this.sendTrips();
      else
        Swal.fire({
          icon: "warning",
          title: "Oops... Missing required entities",
          text: message
        });
    },
    sendTrips: async function() {
      try {
        await api.sendHardFail(this.email);
        Swal.fire(
          "Great!",
          `Info sent to ${tmsConfig.tmsName} successfully.`,
          "success"
        ).then(() => (this.verified = true));
      } catch (err) {
        console.error(err.message);
        const errorCode = err.response ? err.response.status : 500;
        if (errorCode === 404) err.message = "Quote could not be updated";
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Could not get send info: ${err.message}`
        });
      }
    }
  },
  created: async function() {
    try {
      this.isLoading = true;
      const { data: quote } = await api.getHardFailById(this.id);
      if (!quote) throw { message: "Could not find the requested email" };
      this.email = quote;
      this.verified = quote.custom_verified;
      this.date = new Date(this.email.created_at).toLocaleString();
      this.isLoading = false;
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err.message}`
      }).then(() =>
        this.$router.push({
          path: "/notFound",
          query: { redirect: false }
        })
      );
    }
  }
};
</script>

<style lang="scss">
$tabColor: #bfd3da;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

div.hard-fail {
  background-color: #fff;
  min-height: calc(100vh - 150px);
  max-width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @include for-desktop-up {
    max-width: 1600px;
    margin: 0 auto;
  }
}

.hardfail-header {
  flex-basis: 100%;
  max-width: 100%;
  height: 350px;
  word-break: break-word;
  background-color: var(--app-font-color);
  opacity: 0.9;
  color: white;
  margin-bottom: 30px;

  display: flex;
  flex-wrap: wrap;

  @include for-tablet-portrait-up {
    height: 320px;
  }

  @include for-tablet-landscape-up {
    height: 170px;
  }

  .message {
    flex-basis: 100%;
    flex-grow: 2;

    font-weight: bold;
    font-size: 1.5em;
    letter-spacing: 3px;
    text-shadow: 1px 1px 5px #000;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    @include for-tablet-landscape-up {
      font-size: 1.2em;
      flex-basis: 25%;
      flex-grow: 1;
      order: -1;
    }

    @include for-desktop-up {
      font-size: 1.5em;
    }
  }

  .thanks-message {
    flex-basis: 100%;
    text-shadow: 1px 1px 5px #000;
    font-size: 4em;

    display: flex;
    align-items: center;
    justify-content: center;

    @include for-tablet-landscape-up {
      flex-basis: 25%;
      flex-grow: 1;
      order: -1;
    }
  }

  .logo {
    background-color: #fff;
    flex-basis: 100%;
    padding: 20px;
    height: 130px;

    @include for-tablet-portrait-up {
      height: 170px;
      padding: 20px 40px;
    }

    @include for-tablet-landscape-up {
      flex-basis: 600px;
    }

    .tms-logo {
      @include for-tablet-portrait-up {
        height: 100px;
      }
    }
  }
}

.wrapper {
  flex-shrink: 1;
  flex-basis: 100%;
  max-width: 95vw;
  padding: 15px;
  overflow: hidden;

  @include for-desktop-up {
    flex-basis: 50%;
  }

  .wrapper-title {
    font-weight: bold;
    padding-bottom: 15px;
  }
}

.email-wrapper .email-html {
  background-color: #fff;
  box-shadow: 0px 3px 2px #a0a0a0;
  border-radius: 5px;
  overflow-y: scroll;
  height: 90%;
  min-width: 0;
  max-width: 100% !important;
}

.thanks-wrapper {
  font-size: 1.5em;
  text-transform: uppercase;
  text-shadow: 0px 1px 1px red;
  padding: 0 50px;
}

.tab-menu {
  padding: 0 !important;
  float: left;

  .tab-link {
    outline: none;
    border: none;
    padding: 5px 10px;
    background-color: transparent;

    &.active {
      background-color: $tabColor;
    }
  }
}

div.trip {
  display: none;
  padding: 0;
  clear: both;

  &.active {
    display: block;
  }
}

.info-wrapper .form-wrapper {
  .trip-form {
    background-color: $tabColor;
  }
}

.submit-button {
  color: #fff;
  background-color: #8b7667;
  padding: 8px 16px;
  border: none;
  outline: none;
  margin: 10px 10px 10px 0;
  transition: background-color 250ms ease-in-out;
  text-transform: capitalize;
  border-radius: 3px;

  &:hover {
    background-color: #a39082;
  }
}
</style>
