<template>
  <div id="email-list">
    <template v-if="isLoading">
      <LoadingSpinner text="Loading Emails..." />
    </template>
    <template v-else>
      <div class="header">
        <h1 class="title">{{ params.title }}</h1>
      </div>
      <ListTable :type="type" @searchEmails="searchForEmails" />
      <PaginationPanel
        :page="page"
        :limit="limit"
        @getFirstEmails="getFirstEmails"
        @getPreviousEmails="getPreviousEmails"
        @getNextEmails="getNextEmails"
        @getLastEmails="getLastEmails"
      />
    </template>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as auth from "@/middlewares/auth";
import * as api from "@/api/services";
import * as helpers from "@/utils/helpers";
import config from "@/utils/config.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ListTable from "@/components/ListTable.vue";
import PaginationPanel from "@/components/PaginationPanel.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "EmailList",
  props: {
    type: String
  },
  components: {
    LoadingSpinner,
    ListTable,
    PaginationPanel
  },
  computed: {
    ...mapState("user", [""]),
    ...mapState("emailList", ["emails"])
  },
  data: function() {
    return {
      isLoading: true,
      page: 0,
      limit: config.emailsPerTable,
      params: {}
    };
  },
  methods: {
    ...mapActions("emailList", ["getEmails", "sortEmails"]),
    ...mapMutations(["setStatus"]),
    ...mapMutations("user", ["logoutUser", "setUserStatus"]),
    skip: function(page = this.page, limit = this.limit) {
      return limit * page;
    },
    getEmailsNumber: async function() {
      try {
        const state = helpers.getStatusByType(this.type);
        const { data } = await api.countEmails(state);
        return data;
      } catch (err) {
        console.error(err.message);
        if (err.response && err.response.status === 403) {
          const errorMessage = err.response.data.detail;
          this.logoutUser();
          auth.logout();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorMessage
          }).then(() => {
            this.$router.push({ name: "login", params: { noAuth: true } });
          });
        } else
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Could not get response from Charlie: ${err.message}`
          }).then(() => this.$router.push({ name: "home" }));
      }
    },
    getFirstEmails: function() {
      try {
        if (this.page !== 0) {
          this.page = 0;
          this.getData();
        } else throw new Error("You've already reached the first emails");
      } catch (err) {
        console.error(err.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.message
        });
      }
    },
    getPreviousEmails: function() {
      try {
        if (this.page > 0) {
          this.page--;
          this.getData();
        } else throw new Error("You've already reached the first emails");
      } catch (err) {
        console.error(err.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.message
        });
      }
    },
    getNextEmails: async function() {
      try {
        const emailsNumber = await this.getEmailsNumber();
        if (!emailsNumber) throw new Error("Could not get emails count");
        const skip = this.skip(this.page + 1);
        if (skip <= emailsNumber.count) {
          this.page++;
          await this.getData();
        } else if (typeof emailsNumber !== "undefined")
          throw new Error("No more emails to show");
      } catch (err) {
        console.error(err.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.message
        });
      }
    },
    getLastEmails: async function() {
      try {
        const emailsNumber = await this.getEmailsNumber();
        if (!emailsNumber) throw new Error("Could not get emails count");
        const lastPage = parseInt(emailsNumber.count / this.limit);
        if (lastPage > this.page) {
          this.page = lastPage;
          await this.getData();
        } else if (typeof emailsNumber !== "undefined")
          throw new Error("No more emails to show");
      } catch (err) {
        console.error(err.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.message
        });
      }
    },
    async searchForEmails(searchValue) {
      try {
        const state = helpers.getStatusByType(this.type);
        const payload = {
          state,
          skip: this.skip(),
          limit: this.limit,
          searchValue
        };
        await this.getEmails(payload);
      } catch (err) {
        console.error(err.message);
      }
    },
    getData: async function() {
      try {
        const state = helpers.getStatusByType(this.type);
        /* this.setStatus(state); */
        const payload = {
          state,
          skip: this.skip(),
          limit: this.limit
        };
        this.isLoading = true;
        await this.getEmails(payload);
        this.isLoading = false;
        if (this.type !== "ner")
          this.sortEmails({ columnData: "date", dir: this.params.dir });
      } catch (err) {
        console.error(err.message);
        if (err.response && err.response.status === 403) {
          this.logoutUser();
          auth.logout();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.message
          }).then(() => {
            this.$router.push({ name: "login", params: { noAuth: true } });
          });
        } else
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Could not get response from Charlie: ${err.message}`
          }).then(() => this.$router.push({ name: "home" }));
      }
    }
  },
  async created() {
    this.$store.commit("user/tokenValidation");
    this.params = helpers.initParametersByType(this.type);
    await this.getData();
    this.setUserStatus("active");
  }
};
</script>

<style lang="scss" coped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#email-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.title {
  padding: 20px 0;
  color: var(--subtitle-color);
  font-size: 2rem;
  font-weight: bold;
}

.list-table {
  flex-grow: 1;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 50px;
}

.pagination {
  align-self: flex-end;
  padding-left: 50px;
  padding-right: 50px;

  @include for-phone-only {
    align-self: center;
  }
}
</style>
