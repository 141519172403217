<template>
  <div class="selectbox-wrapper">
    <div class="box">
      <select class="selectbox">
        <option
          v-for="option in options"
          class="option"
          :key="option"
          :value="option"
        >
          {{ option | removeUnderscores }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchSelectBox",
  props: {
    options: Array
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--app-font-family);
}

.selectbox-wrapper {
  display: inline-block;
  margin: 0 10px 0 0;
}

.box {
  position: relative;

  &:before {
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #a3a3a3;
    pointer-events: none;
    line-height: 35px;
    font-weight: 900;
  }
}

.selectbox {
  background-color: #ffffff;
  border: 1px solid #a3a3a3;
  color: var(--text-color);
  outline: none;
  border-radius: 6px;
  padding: 12px;
  font-weight: bold;
  font-size: 0.87rem;
  width: 100%;
}

.box:before,
.selectbox,
.selectbox option {
  cursor: pointer;
  text-transform: capitalize;
}
</style>
